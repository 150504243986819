import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { Parallax } from '@react-spring/parallax'

import Layout from '../components/Layout'

import Hero from '../views/Hero'
import { BigTitle, H2Title, Text, Disclaimer, Input, Label, InputContainer, Button } from '../elements'

// TODO: Add validations for input fields.

const BMI_RANGES = [
  {
    min: 0,
    max: 18.5,
    range: 'Underweight',
  },
  {
    min: 18.5,
    max: 24.9,
    range: 'Normal weight',
  },
  {
    min: 25,
    max: 29.9,
    range: 'Overweight',
  },
  {
    min: 30,
    max: Number.MAX_SAFE_INTEGER,
    range: 'Obese',
  },
]

export default class BmiCalculator extends Component {
  state = {
    height: 0,
    weight: 0,
    bmi: null,
    bmiRange: '',
  }

  handleInputChange = (event) => {
    const { name, value } = event.target

    this.setState({
      [name]: value,
    })
  }

  onCalculate = () => {
    const { height, weight } = this.state
    if (weight && height) {
      const denominator = (parseFloat(height) / 100) ** 2
      const bmi = (parseFloat(weight) / denominator).toFixed(2)
      const calculatedBmiRange = BMI_RANGES.find((range) => range.min < bmi && bmi < range.max)
      console.log(bmi)
      this.setState({ bmi, bmiRange: calculatedBmiRange.range })
    }
  }

  renderResults() {
    const { bmi, bmiRange } = this.state
    return (
      <Fragment>
        {bmi && <Text>Your BMI is {bmi}</Text>}
        {bmiRange && <Text>You are {bmiRange}</Text>}
      </Fragment>
    )
  }

  render() {
    const { height, weight } = this.state
    return (
      <Fragment>
        <Layout />
        <Helmet title="OzCalculators | BMI Calculator" />
        <Parallax pages={2}>
          <Hero offset={0}>
            <BigTitle>BMI Calculator</BigTitle>
            <Text>Scroll down to view important information regarding this BMI Calculator.</Text>
            <InputContainer>
              <Label>Height in cm:</Label>
              <Input type="text" name="height" value={height} onChange={this.handleInputChange} />
            </InputContainer>
            <InputContainer>
              <Label>Weight in kg:</Label>
              <Input type="text" name="weight" value={weight} onChange={this.handleInputChange} />
            </InputContainer>
            <Text>
              <Button onClick={this.onCalculate}>Calculate your BMI</Button>
            </Text>
            {this.renderResults()}
            <Disclaimer>
              Note: This calculator shouldn't be used for measuring BMI of children or pregnant women.
            </Disclaimer>
          </Hero>
          <Hero offset={1}>
            <H2Title>What is a BMI Calculator?</H2Title>
            <Text>
              BMI can indicate if a person is underweight, healthy, overweight or obese. If a person's BMI is outside
              the healthy range, health risks may increase significantly.
            </Text>
            <Text>
              BMI doesn't measure body fat directly and does not take into account age, sex, ethnicity and body
              composition.
            </Text>
            <Text>This calculator shouldn't for measuring BMI for children or pregnant women.</Text>
            <Text>
              Disclaimer: While utmost care has been taken to keep this accurate, it should not be used as a conclusive
              tool for assessing health risks. Always speak with your doctor about health risks associated with your
              weight.
            </Text>
          </Hero>
        </Parallax>
      </Fragment>
    )
  }
}
